<template>
  <div class="course-edit">
    <!--v-loading.fullscreen.lock="pageLoading"-->
    <el-card class="box-card detailHeaderBox"
             shadow="never">
      <div class="course-detail-header"
           :style="{ 'background-image': 'url('+defaultHeaderBg+')', 'background-repeat': 'no-repeat' , 'background-size': '100% 6.25vw' }">
        <div class="header-left">
          <div class="img-div">
            <img :src="courseInfo.coverImg ? courseInfo.coverImg : defaultHeaderImg"
                 class="course-img" />
            <!-- <img :src="imgOfflineIcon" alt="" class="status-icon" v-if="courseInfo.status == '0'"/>
            <img :src="imgOnlineIcon" alt="" class="status-icon" v-if="courseInfo.status == '1'"/> -->
          </div>
          <div class="course-info">
            <div class="title">
              <div class="icon-div">{{ courseInfo.status == '0' ? '已下架' : courseInfo.status == '1' ? '已上架' : '' }}</div>
              <span>{{ courseInfo.courseName }}</span>
            </div>
            <div class="course-info-bottom">
              <div class="bottom-item">
                <div class="label">类型：</div>
                <div class="text">{{ courseInfo.courseClassifyName }}</div>
                <div class="line"></div>
              </div>
              <div class="bottom-item">
                <div class="label">领域：</div>
                <div class="text">{{ courseInfo.courseFieldName }}</div>
                <div class="line"></div>
              </div>
              <div class="bottom-item">
                <div class="label">系列：</div>
                <div class="text">{{ courseInfo.courseThemeName }}</div>
                <div class="line"></div>
              </div>
              <div class="bottom-item">
                <div class="label">版本：</div>
                <div class="text">{{ courseInfo.courseVersionName }}</div>
                <div class="line"></div>
              </div>
              <div class="bottom-item"
                   v-if="courseInfo.courseLevel">
                <div class="label">等级：</div>
                <div class="text">{{ courseInfo.courseLevelName }}</div>
                <div class="line"></div>
              </div>
              <div class="bottom-item"
                   v-if="courseInfo.courseSuitable">
                <div class="label">阶段：</div>
                <div class="text">{{ courseInfo.courseSuitableName }}</div>
                <div class="line"></div>
              </div>
              <div class="bottom-item">
                <div class="label">老师：</div>
                <div class="text">{{ courseInfo.userName }}</div>
                <div class="line"></div>
              </div>
              <div class="bottom-item">
                <div class="label long">创建时间：</div>
                <div class="text long">{{ courseInfo.createTime }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="header-right">
          <el-tooltip class="item"
                      effect="light"
                      popper-class="customPopper"
                      content="编辑"
                      placement="bottom"
                      v-if="isUpdate && courseInfo.status == '0'">
            <img :src="detailEditIconHeader"
                 alt=""
                 @click="courseEdit"
                 v-if="isUpdate && courseInfo.status == '0'" />
          </el-tooltip>
          <el-tooltip class="item"
                      effect="light"
                      popper-class="customPopper"
                      content="上架"
                      placement="bottom"
                      v-if="courseInfo.status == '0' && isUpDown">
            <img :src="detailOnlineIconHeader"
                 alt=""
                 @click="changeStatus('1')"
                 v-if="courseInfo.status == '0' && isUpDown" />
          </el-tooltip>
          <el-tooltip class="item"
                      effect="light"
                      popper-class="customPopper"
                      content="下架"
                      placement="bottom"
                      v-if="courseInfo.status == '1' && isUpDown">
            <img :src="detailOfflineIconHeader"
                 alt=""
                 @click="changeStatus('0')"
                 v-if="courseInfo.status == '1' && isUpDown" />
          </el-tooltip>
          <el-tooltip class="item"
                      effect="light"
                      popper-class="customPopper"
                      content="删除"
                      placement="bottom"
                      v-if="isDelete && courseInfo.status == '0'">
            <img :src="detailDelIconHeader"
                 alt=""
                 @click="deleteCourse"
                 v-if="isDelete && courseInfo.status == '0'" />
          </el-tooltip>
        </div>
      </div>
      <div class="course-detail-title">
        <div class="text"
             :class="{ active: activeTab == 'courseIntroduce' }"
             @click="handeTabClick('courseIntroduce')">
          <span>课程介绍</span>
          <div class="line"
               v-show="activeTab == 'courseIntroduce'"></div>
        </div>
        <div class="text"
             :class="{ active: activeTab == 'courseContent' }"
             @click="handeTabClick('courseContent')">
          <span>课程内容</span>
          <div class="line"
               v-show="activeTab == 'courseContent'"></div>
        </div>
        <el-tooltip class="item"
                    effect="light"
                    popper-class="customPopper"
                    content="内容设置"
                    placement="top"
                    v-if="isSetting && activeTab == 'courseIntroduce' && courseInfo.status == '0'">
          <img :src="isContentSettingHover ? detailCatalogContentSettingActiveIcon : detailCatalogContentSettingIcon"
               alt=""
               @click="handleCatalogItem()"
               v-if="isSetting && activeTab == 'courseIntroduce'"
               @mouseenter="handleHover('content',true)"
               @mouseleave="handleHover('content',false)" />
        </el-tooltip>
      </div>
    </el-card>
    <div class="tab-info"
         v-show="activeTab == 'courseIntroduce'">
      <div class="panel-data"
           v-if="courseIntroInfo.length > 0">
        <div class="first-panel edit"
             v-for="(item, index) in courseIntroInfo"
             :key="item.id">
          <div class="panel-header">
            <div class="title">
              <div class="title-icon"></div>{{ item.catalogueName }}
            </div>
            <div class="button">
              <el-tooltip class="item"
                          effect="light"
                          popper-class="customPopper"
                          content="附件上传"
                          placement="bottom"
                          v-if="item.eduCourseIntroContentTree.length == 0 && item.catalogueType == '0' && isUpdate && courseInfo.status == '0'">
                <img :src="uploadFileIcon"
                     alt=""
                     @click="handleContentItem('addFile', null, null, item, index)"
                     v-if="item.eduCourseIntroContentTree.length == 0 && item.catalogueType == '0' && isUpdate && courseInfo.status == '0'"
                     style="margin-right: 0.41667vw" />
              </el-tooltip>
              <el-divider direction="vertical"
                          v-if="item.eduCourseIntroContentTree.length == 0 && item.catalogueType == '0' && isUpdate && courseInfo.status == '0'"></el-divider>
              <el-tooltip class="item"
                          effect="light"
                          popper-class="customPopper"
                          content="图文编辑"
                          placement="bottom"
                          v-if="item.eduCourseIntroContentTree.length == 0 && item.catalogueType == '0' && isUpdate && courseInfo.status == '0'">
                <img :src="editIcon"
                     alt=""
                     @click="handleContentItem('editItem', null, null, item, index)"
                     v-if="item.eduCourseIntroContentTree.length == 0 && item.catalogueType == '0' && isUpdate && courseInfo.status == '0'"
                     style="margin: 0 0.41667vw" />
              </el-tooltip>
              <el-tooltip class="item"
                          effect="light"
                          popper-class="customPopper"
                          content="视频添加"
                          placement="bottom"
                          v-if="item.eduCourseIntroContentTree.length == 0 && item.catalogueType == '1' && isUpdate && courseInfo.status == '0'">
                <img :src="videoAddIcon"
                     alt=""
                     @click="handleContentItem('addVideo', null, null, item, index)"
                     v-if="item.eduCourseIntroContentTree.length == 0 && item.catalogueType == '1' && isUpdate && courseInfo.status == '0'"
                     style="margin-right: 0.41667vw" />
              </el-tooltip>
              <el-divider direction="vertical"
                          v-if="item.eduCourseIntroContentTree.length == 0 && isUpdate && courseInfo.status == '0'"></el-divider>
              <img :src="firstPanelTurnIcon"
                   alt=""
                   @click="handleContentItem('turnItem', null, null, item, index)"
                   style="margin-left: 0.41667vw"
                   :class="{ 'turn-icon-class': !item.showFlag }" />
            </div>
          </div>
          <div class="panel-content"
               v-show="item.showFlag"
               v-if="item.eduCourseIntroContentTree.length > 0">
            <div class="second-panel"
                 v-for="(info, i) in item.eduCourseIntroContentTree"
                 :key="info.id">
              <div class="panel-header">
                <div class="title">{{ info.catalogueName }}</div>
                <div class="button">
                  <el-tooltip class="item"
                              effect="light"
                              popper-class="customPopper"
                              content="附件上传"
                              placement="bottom"
                              v-if="info.catalogueType == '0' && isUpdate && courseInfo.status == '0'">
                    <img :src="uploadFileIcon"
                         alt=""
                         @click="handleContentItem('addFile', info, i, item, index)"
                         v-if="info.catalogueType == '0' && isUpdate && courseInfo.status == '0'"
                         style="margin-right: 0.41667vw" />
                  </el-tooltip>
                  <el-divider direction="vertical"
                              v-if="info.catalogueType == '0' && isUpdate && courseInfo.status == '0'"></el-divider>
                  <el-tooltip class="item"
                              effect="light"
                              popper-class="customPopper"
                              content="图文编辑"
                              placement="bottom"
                              v-if="info.catalogueType == '0' && isUpdate && courseInfo.status == '0'">
                    <img :src="editIcon"
                         alt=""
                         @click="handleContentItem('editItem', info, i, item, index)"
                         v-if="info.catalogueType == '0' && isUpdate && courseInfo.status == '0'"
                         style="margin: 0 0.41667vw" />
                  </el-tooltip>
                  <el-tooltip class="item"
                              effect="light"
                              popper-class="customPopper"
                              content="视频添加"
                              placement="bottom"
                              v-if="info.catalogueType == '1' && isUpdate && courseInfo.status == '0'">
                    <img :src="videoAddIcon"
                         alt=""
                         @click="handleContentItem('addVideo', info, i, item, index)"
                         v-if="info.catalogueType == '1' && isUpdate && courseInfo.status == '0'"
                         style="margin-right: 0.41667vw" />
                  </el-tooltip>
                  <el-divider direction="vertical"
                              v-if="isUpdate && courseInfo.status == '0'"></el-divider>
                  <img :src="secondPanelTurnIcon"
                       alt=""
                       @click="handleContentItem('turnItem', info, i, item, index)"
                       style="margin-left: 0.41667vw"
                       :class="{ 'turn-icon-class': !info.showFlag }" />
                </div>
              </div>
              <div class="panel-content"
                   v-show="info.showFlag"
                   v-if="info.catalogueType == '0'">
                <div class="content-info"
                     v-if="info.eduCourseIntroContent.length > 0">
                  <!-- <div style="width: 100%" v-html="info.eduCourseIntroContent[0].content"></div> -->
                  <div class="w-e-text-container"
                       @click="handleImgClick">
                    <div class="w-e-text"
                         v-html="info.eduCourseIntroContent[0].content"></div>
                  </div>
                </div>
                <div class="file-info"
                     v-if="info.eduCourseIntroFile && info.eduCourseIntroFile.length > 0">
                  <div class="file-item"
                       v-for="(fileInfo, fileIndex) in info.eduCourseIntroFile"
                       :key="fileInfo.id"
                       :style="{ 'margin-right': ((fileIndex+1) % fileNum == 0) ? '0' : '0.625vw' }">
                    <div class="content">
                      <img class="icon"
                           :src="fileIconPDF"
                           alt=""
                           v-if="fileInfo.iconType == 'pdf'">
                      <img class="icon"
                           :src="fileIconWord"
                           alt=""
                           v-if="fileInfo.iconType == 'doc'">
                      <img class="icon"
                           :src="fileIconExcel"
                           alt=""
                           v-if="fileInfo.iconType == 'xls'">
                      <img class="icon"
                           :src="fileIconPPT"
                           alt=""
                           v-if="fileInfo.iconType == 'ppt'" />
                      <div class="message">
                        <el-tooltip class="item"
                                    effect="light"
                                    popper-class="customPopper"
                                    :content="fileInfo.originalName"
                                    placement="top-start">
                          <div class="title">{{ fileInfo.originalName }}</div>
                        </el-tooltip>
                        <el-tooltip class="item"
                                    effect="light"
                                    popper-class="customPopper"
                                    :content="fileInfo.remark"
                                    placement="top-start">
                          <div class="ltitle">{{ fileInfo.remark }}</div>
                        </el-tooltip>
                      </div>
                    </div>
                    <div class="bottom">
                      <el-tooltip class="item"
                                  effect="light"
                                  popper-class="customPopper"
                                  content="附件下载"
                                  placement="bottom"
                                  v-if="fileInfo.isDownload == '1'">
                        <img :src="fileDownloadIcon"
                             alt=""
                             @click="fileView(fileInfo,'download')"
                             style="margin-right: 0.41667vw"
                             v-if="fileInfo.isDownload == '1'" />
                      </el-tooltip>
                      <el-divider direction="vertical"
                                  v-if="fileInfo.isDownload == '1'"></el-divider>
                      <el-tooltip class="item"
                                  effect="light"
                                  popper-class="customPopper"
                                  content="附件预览"
                                  placement="bottom">
                        <img :src="fileViewIcon"
                             alt=""
                             @click="fileView(fileInfo,'view')"
                             style="margin: 0 0.26042vw 0 0.41667vw" />
                      </el-tooltip>
                      <el-divider direction="vertical"
                                  v-if="isUpdate && courseInfo.status == '0'"></el-divider>
                      <el-tooltip class="item"
                                  effect="light"
                                  popper-class="customPopper"
                                  content="附件删除"
                                  placement="bottom"
                                  v-if="isUpdate && courseInfo.status == '0'">
                        <img :src="delIcon"
                             alt=""
                             v-if="isUpdate && courseInfo.status == '0'"
                             @click="delFile(fileInfo)"
                             style="margin: 0 0.26042vw 0 0.41667vw" />
                      </el-tooltip>
                    </div>
                  </div>
                </div>
              </div>
              <div class="panel-content"
                   v-show="info.showFlag"
                   v-if="info.catalogueType == '1'">
                <el-row :style="{ 'margin': '1.25vw 0.9375vw' }">
                  <!--type="flex"-->
                  <!-- <el-col :span="videoSpan" v-for="(video, k) in info.eduCourseIntroVideo" :key="video.id" :style="{ 'margin-right': (k + 1) % videoNum != 0 ? '4vw' : '0', 'margin-bottom': '1.04167vw', }"> -->
                  <el-card shadow="hover"
                           class="video-card"
                           v-for="(video, k) in info.eduCourseIntroVideo"
                           :key="video.id"
                           :style="{ 'margin-right': (k + 1) % videoNum != 0 ? '4vw' : '0', 'margin-bottom': '1.04167vw', 'width': '22.08333vw', display: 'inline-block' }"
                           :body-style="{ padding: 'undefined' }"
                           @click.native="videoView(video)">
                    <div class="video-body"
                         :style="{ 'background-image': 'url(' + (video.fontImg ? video.fontImg : defaultImg) + ')', 'background-size': '100% 100%', }">
                      <img :src="videoPlayIcon"
                           alt="">
                    </div>
                    <div class="info-body video-item">
                      <el-tooltip class="item"
                                  effect="light"
                                  popper-class="customPopper"
                                  :content="video.videoName"
                                  placement="top">
                        <div class="title">{{ video.videoName }}</div>
                      </el-tooltip>
                      <el-tooltip class="item"
                                  effect="light"
                                  popper-class="customPopper"
                                  :content="video.intro"
                                  placement="top"
                                  v-if="video.intro">
                        <div class="ltitle"
                             v-if="video.intro">{{ video.intro }}</div>
                      </el-tooltip>
                      <div class="bottom">
                        <div class="video-time">{{ video.createTime }}</div>
                        <div class="video-button">
                          <el-tooltip class="item"
                                      effect="light"
                                      popper-class="customPopper"
                                      content="视频编辑"
                                      placement="bottom"
                                      v-if="isUpdate && courseInfo.status == '0'">
                            <img :src="editIcon"
                                 alt=""
                                 v-if="isUpdate && courseInfo.status == '0'"
                                 @click.stop="editVideo(video, k, info, i, item, index)"
                                 style="margin-right: 15%" />
                          </el-tooltip>
                          <el-divider direction="vertical"
                                      v-if="isUpdate && courseInfo.status == '0'"></el-divider>
                          <el-tooltip class="item"
                                      effect="light"
                                      popper-class="customPopper"
                                      content="视频删除"
                                      placement="bottom"
                                      v-if="isUpdate && courseInfo.status == '0'">
                            <img :src="delIcon"
                                 alt=""
                                 v-if="isUpdate && courseInfo.status == '0'"
                                 @click.stop="delVideo(video)"
                                 style="margin-left: 15%" />
                          </el-tooltip>
                        </div>
                      </div>
                    </div>
                  </el-card>
                  <!-- </el-col> -->
                </el-row>
              </div>
            </div>
          </div>
          <div class="panel-content"
               v-show="item.showFlag"
               v-if="item.eduCourseIntroContentTree.length == 0">
            <div class="content-panel"
                 v-if="item.catalogueType == '0'">
              <div class="content-info"
                   v-if="item.eduCourseIntroContent.length > 0">
                <!-- <div style="width: 100%" v-html="item.eduCourseIntroContent[0].content"></div> -->
                <div class="w-e-text-container"
                     @click="handleImgClick">
                  <div class="w-e-text"
                       v-html="item.eduCourseIntroContent[0].content"></div>
                </div>
              </div>
              <div class="file-info"
                   v-if="item.eduCourseIntroFile && item.eduCourseIntroFile.length > 0">
                <div class="file-item"
                     v-for="(fileInfo, fileIndex) in item.eduCourseIntroFile"
                     :key="fileInfo.id"
                     :style="{ 'margin-right': ((fileIndex+1) % fileNum == 0) ? '0' : '0.625vw' }">
                  <div class="content">
                    <img class="icon"
                         :src="fileIconPDF"
                         alt=""
                         v-if="fileInfo.iconType == 'pdf'">
                    <img class="icon"
                         :src="fileIconWord"
                         alt=""
                         v-if="fileInfo.iconType == 'doc'">
                    <img class="icon"
                         :src="fileIconExcel"
                         alt=""
                         v-if="fileInfo.iconType == 'xls'">
                    <img class="icon"
                         :src="fileIconPPT"
                         alt=""
                         v-if="fileInfo.iconType == 'ppt'" />
                    <div class="message">
                      <el-tooltip class="item"
                                  effect="light"
                                  popper-class="customPopper"
                                  :content="fileInfo.originalName"
                                  placement="top-start">
                        <div class="title">{{ fileInfo.originalName }}</div>
                      </el-tooltip>
                      <el-tooltip class="item"
                                  effect="light"
                                  popper-class="customPopper"
                                  :content="fileInfo.remark"
                                  placement="top">
                        <div class="ltitle">{{ fileInfo.remark }}</div>
                      </el-tooltip>
                    </div>
                  </div>
                  <div class="bottom">
                    <el-tooltip class="item"
                                effect="light"
                                popper-class="customPopper"
                                content="附件下载"
                                placement="bottom"
                                v-if="fileInfo.isDownload == '1'">
                      <img :src="fileDownloadIcon"
                           alt=""
                           @click="fileView(fileInfo,'download')"
                           style="margin-right: 0.41667vw"
                           v-if="fileInfo.isDownload == '1'" />
                    </el-tooltip>
                    <el-divider direction="vertical"
                                v-if="fileInfo.isDownload == '1'"></el-divider>
                    <el-tooltip class="item"
                                effect="light"
                                popper-class="customPopper"
                                content="附件预览"
                                placement="bottom">
                      <img :src="fileViewIcon"
                           alt=""
                           @click="fileView(fileInfo,'view')"
                           style="margin: 0 0.26042vw 0 0.41667vw" />
                    </el-tooltip>
                    <el-divider direction="vertical"
                                v-if="isUpdate && courseInfo.status == '0'"></el-divider>
                    <el-tooltip class="item"
                                effect="light"
                                popper-class="customPopper"
                                content="附件删除"
                                placement="bottom"
                                v-if="isUpdate && courseInfo.status == '0'">
                      <img :src="delIcon"
                           alt=""
                           v-if="isUpdate && courseInfo.status == '0'"
                           @click="delFile(fileInfo)"
                           style="margin: 0 0.26042vw 0 0.41667vw" />
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
            <div class="content-panel"
                 v-if="item.catalogueType == '1'">
              <el-row :style="{ 'margin-top': '1.25vw' }">
                <!--type="flex"-->
                <!-- <el-col :span="videoSpan" v-for="(video, k) in item.eduCourseIntroVideo" :key="video.id" :style="{ 'margin-right': (k + 1) % videoNum != 0 ? '4vw' : '0', 'margin-bottom': '1.04167vw', }"> -->
                <el-card shadow="hover"
                         class="video-card"
                         v-for="(video, k) in item.eduCourseIntroVideo"
                         :key="video.id"
                         :style="{ 'margin-right': (k + 1) % videoNum != 0 ? '5vw' : '0', 'margin-bottom': '1.04167vw' , 'width': '22.08333vw', display: 'inline-block' }"
                         :body-style="{ padding: 'undefined' }"
                         @click.native="videoView(video)">
                  <div class="video-body"
                       :style="{ 'background-image': 'url(' + (video.fontImg ? video.fontImg : defaultImg) + ')', 'background-size': '100% 100%', }">
                    <img :src="videoPlayIcon"
                         alt="">
                  </div>
                  <div class="info-body video-item">
                    <el-tooltip class="item"
                                effect="light"
                                popper-class="customPopper"
                                :content="video.videoName"
                                placement="top">
                      <div class="title">{{ video.videoName }}</div>
                    </el-tooltip>
                    <el-tooltip class="item"
                                effect="light"
                                popper-class="customPopper"
                                :content="video.intro"
                                placement="top"
                                v-if="video.intro">
                      <div class="ltitle"
                           v-if="video.intro">{{ video.intro }}</div>
                    </el-tooltip>
                    <div class="bottom">
                      <div class="video-time">{{ video.createTime }}</div>
                      <div class="video-button">
                        <el-tooltip class="item"
                                    effect="light"
                                    popper-class="customPopper"
                                    content="视频编辑"
                                    placement="bottom"
                                    v-if="isUpdate && courseInfo.status == '0'">
                          <img :src="editIcon"
                               alt=""
                               v-if="isUpdate && courseInfo.status == '0'"
                               @click.stop="editVideo(video, k, null, null, item, index)"
                               style="margin-right: 15%" />
                        </el-tooltip>
                        <el-divider direction="vertical"
                                    v-if="isUpdate && courseInfo.status == '0'"></el-divider>
                        <el-tooltip class="item"
                                    effect="light"
                                    popper-class="customPopper"
                                    content="视频删除"
                                    placement="bottom"
                                    v-if="isUpdate && courseInfo.status == '0'">
                          <img :src="delIcon"
                               alt=""
                               v-if="isUpdate && courseInfo.status == '0'"
                               @click.stop="delVideo(video)"
                               style="margin-left: 15%" />
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                </el-card>
                <!-- </el-col> -->
              </el-row>
            </div>
          </div>
        </div>
      </div>
      <div class="empty-panel"
           v-if="courseIntroInfo.length == 0">
        <img class="empty-img"
             :src="emptyImg"
             alt="" />
        <div class="empty-title">您还没设置课程介绍内容</div>
        <div class="empty-ltitle">需要先进行内容设置后才能进行内容操作</div>
        <!-- <el-button class="empty-button" type="primary" plain round @click="handleCourseIntro" v-if="isSetting">内容设置</el-button> -->
      </div>
      <el-image style="width: 0; height: 0;"
                ref="viewImg"
                :src="viewUrl"
                :preview-src-list="viewUrlList"></el-image>
    </div>
    <div class="tab-info"
         v-show="activeTab == 'courseContent'">
      <div class="course-content-tab">
        <!-- 表单 -->
        <el-form :model="searchForm"
                 onsubmit="return false"
                 :inline="true">
          <el-form-item>
            <el-select v-model="searchForm.status"
                       clearable
                       placeholder="请选择状态">
              <el-option v-for="item in statusOptions"
                         :key="item.value"
                         :label="item.name"
                         :value="item.value"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button type="primary"
                       icon="el-icon-search"
                       @click="searchFun">查询</el-button>
          </el-form-item>

          <el-form-item>
            <el-button icon="el-icon-refresh-right"
                       @click="resetFun">重置</el-button>
          </el-form-item>

          <el-form-item style="float: right"
                        v-if="isUpdate">
            <!--&& courseInfo.status == '0'-->
            <el-button type="primary"
                       @click="handleAdd">添加</el-button>
          </el-form-item>
          <!--v-if="permissions.includes('sys_dict_add')"-->
        </el-form>
        <div class="table-content"
             v-show="courseContentList.length > 0">
          <el-card shadow="hover"
                   :body-style="{ padding: 'undefined' }"
                   class="course-content-card"
                   @click.native="handleItem('detail',item)"
                   v-for="(item, index) in courseContentList"
                   :key="item.id"
                   :style="{ 'margin-right': (index + 1) % courseContentNum != 0 ? courseContentRight : '0', 'margin-bottom': '1.04167vw', width: '16.30208vw', display: 'inline-block'}">
            <div class="course-body"
                 :style="{ 'background-image': 'url(' + (item.coverImg ? item.coverImg : defaultImg) + ')', 'background-size': '16.30208vw 100%', 'background-repeat': 'no-repeat','background-position-x': '50%', 'background-color': 'aliceblue' , height: '12.1875vw' }">
              <img :src="imgOfflineIcon"
                   alt=""
                   class="status-icon"
                   v-if="item.status == '2'" />
              <img :src="imgOnlineIcon"
                   alt=""
                   class="status-icon"
                   v-if="item.status == '1'" />
              <img :src="imgNopushIcon"
                   alt=""
                   class="status-icon"
                   v-if="item.status == '0'" />
            </div>
            <div class="info-body">
              <el-tooltip class="item"
                          effect="light"
                          popper-class="customPopper"
                          :content="item.courseThemeName"
                          placement="top">
                <div class="title">{{ item.courseThemeName }}</div>
              </el-tooltip>
              <el-tooltip class="item"
                          effect="light"
                          popper-class="customPopper"
                          :content="item.description"
                          placement="top"
                          v-if="item.description">
                <div class="ltitle"
                     v-if="item.description">{{ item.description }}</div>
              </el-tooltip>
              <div class="ltitle"
                   v-if="!item.description"></div>
              <div class="bottom"
                   :style="{ 'justify-content': item.status !== '1' ? '' : 'flex-start' }">
                <el-tooltip class="item"
                            effect="light"
                            popper-class="customPopper"
                            content="目录设置"
                            placement="bottom"
                            v-if="isUpdate && item.status !== '1'">
                  <img :src="catalogSettingIcon"
                       alt=""
                       @click.stop="handleItem('setting', item)"
                       v-if="isUpdate && item.status !== '1'" />
                </el-tooltip>
                <el-divider direction="vertical"
                            v-if="item.status === '2' && isUpdate"></el-divider>
                <el-tooltip class="item"
                            effect="light"
                            popper-class="customPopper"
                            content="下架"
                            placement="bottom"
                            v-if="item.status === '1' && isUpdate">
                  <img :src="offlineIcon"
                       alt=""
                       v-if="item.status === '1' && isUpdate"
                       @click.stop="handleItem('down', item)" />
                </el-tooltip>
                <el-tooltip class="item"
                            effect="light"
                            popper-class="customPopper"
                            content="上架"
                            placement="bottom"
                            v-if="item.status === '2' && isUpdate">
                  <img :src="onlineIcon"
                       alt=""
                       v-if="item.status === '2' && isUpdate"
                       @click.stop="handleItem('up', item)" />
                </el-tooltip>
                <el-divider direction="vertical"
                            v-if="isUpdate && item.status !== '1'"></el-divider>
                <el-tooltip class="item"
                            effect="light"
                            popper-class="customPopper"
                            content="编辑"
                            placement="bottom"
                            v-if="isUpdate && item.status !== '1'">
                  <img :src="editIcon"
                       alt=""
                       v-if="isUpdate && item.status !== '1'"
                       @click.stop="handleItem('edit', item)" />
                </el-tooltip>
                <el-divider direction="vertical"
                            v-if="isUpdate && item.status !== '1'"></el-divider>
                <el-tooltip class="item"
                            effect="light"
                            popper-class="customPopper"
                            content="删除"
                            placement="bottom"
                            v-if="isUpdate && item.status !== '1'">
                  <img :src="delIcon"
                       alt=""
                       v-if="isUpdate && item.status !== '1'"
                       @click.stop="handleItem('del', item)" />
                </el-tooltip>
              </div>
            </div>
          </el-card>
        </div>
        <div class="empty-content"
             v-show="courseContentList.length == 0">
          <img class="empty-img"
               :src="emptyImg"
               alt="" />
          <div class="empty-title">您还没添加任何课程内容</div>
          <div class="empty-ltitle">需要添加后才能执行相关操作</div>
        </div>
        <div v-if="pagination && ipagination.total > 0"
             class="l-pages">
          <div class="total-num">显示第{{ showNum }}条记录，共{{ ipagination.total }}条记录</div>
          <el-pagination @current-change="handleCurrentChange"
                         :current-page.sync="ipagination.num"
                         @size-change="handleSizeChange"
                         :page-size="ipagination.size"
                         :page-sizes="ipagination.pageSizes"
                         layout="prev, pager, next, sizes"
                         :total="ipagination.total"></el-pagination>
        </div>
      </div>
    </div>
    <el-dialog :title="courseTitle"
               :visible.sync="courseVisible"
               :append-to-body="true"
               :destroy-on-close="true"
               @before-close="handleCourseClose"
               @close="handleCourseClose"
               width="50%">
      <el-form :model="courseForm"
               ref="courseForm"
               class="courseForm"
               :rules="rules"
               label-width="7.29167vw">
        <el-row>
          <el-col :span="24">
            <el-form-item label="封面图片："
                          class="form-choose-item">
              <el-upload class="avatar-uploader"
                         :action="uploadUrl"
                         :headers="uploadHeader"
                         :show-file-list="false"
                         :on-success="handleCourseAvatarSuccess"
                         :before-upload="beforeCourseAvatarUpload"
                         :accept="'.png,.jpg,.jpeg'">
                <img v-if="courseForm.coverImg"
                     :src="courseForm.coverImg"
                     class="avatar" />
                <i v-if="courseForm.coverImg"
                   class="el-icon-error delete-icon"
                   @click.stop="delCourseImg"></i>
                <div v-else
                     class="avatar-uploader-info">
                  <img class="avatar-uploader-icon"
                       :src="uploadImgIcon" />
                  <div class="avatar-uploader-title">上传图片</div>
                  <div class="avatar-uploader-tip">图片尺寸比例4:3，仅限上传1张</div>
                </div>
              </el-upload>
              <!-- <div class="upload-tip">图片尺寸比例5:3，仅限上传1张</div> -->
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item"
                          label="名称："
                          prop="courseName">
              <el-input class="form-input"
                        v-model="courseForm.courseName"
                        placeholder="请输入名称"
                        :maxlength="50"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item"
                          label="分类："
                          prop="courseClassify">
              <el-select class="form-input"
                         v-model="courseForm.courseClassify"
                         multiple
                         clearable
                         placeholder="请选择分类">
                <el-option v-for="item in typeOptions"
                           :key="item.value"
                           :label="item.name"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item"
                          label="领域："
                          prop="courseField">
              <el-select class="form-input"
                         v-model="courseForm.courseField"
                         multiple
                         clearable
                         placeholder="请选择领域">
                <el-option v-for="item in areaOptions"
                           :key="item.value"
                           :label="item.name"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item"
                          label="系列："
                          prop="courseTheme">
              <el-select class="form-input"
                         v-model="courseForm.courseTheme"
                         clearable
                         placeholder="请选择系列">
                <el-option v-for="item in themeOptions"
                           :key="item.value"
                           :label="item.name"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item"
                          label="版本："
                          prop="courseVersion">
              <el-select class="form-input"
                         v-model="courseForm.courseVersion"
                         clearable
                         placeholder="请选择版本">
                <el-option v-for="item in versionOptions"
                           :key="item.value"
                           :label="item.name"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item"
                          label="等级or阶段："
                          prop="levelOrStep">
              <el-radio-group v-model="courseForm.levelOrStep"
                              @change="levelOrStepChange">
                <el-radio :label="'1'">等级</el-radio>
                <el-radio :label="'2'">阶段</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24"
                  v-if="courseForm.levelOrStep == '1'">
            <el-form-item class="form-choose-item"
                          label=""
                          prop="courseLevel">
              <el-select class="form-input"
                         v-model="courseForm.courseLevel"
                         multiple
                         clearable
                         placeholder="请选择等级">
                <el-option v-for="item in levelOptions"
                           :key="item.value"
                           :label="item.name"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24"
                  v-if="courseForm.levelOrStep == '2'">
            <el-form-item class="form-choose-item"
                          label=""
                          prop="courseSuitable">
              <el-select class="form-input"
                         v-model="courseForm.courseSuitable"
                         multiple
                         clearable
                         placeholder="请选择阶段">
                <el-option v-for="item in stepOptions"
                           :key="item.value"
                           :label="item.name"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item"
                          label="描述：">
              <el-input class="form-input"
                        type="textarea"
                        :rows="5"
                        placeholder="请填写描述内容..."
                        v-model="courseForm.description"
                        resize="none"
                        :maxlength="100"
                        show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item"
                          label="排序："
                          prop="sort">
              <el-input class="form-input"
                        v-model="courseForm.sort"
                        placeholder="请输入排序"
                        v-input-number
                        :maxlength="10"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button :loading="buttonloading"
                   @click="handleCourseClose">取 消</el-button>
        <el-button :loading="buttonloading"
                   type="primary"
                   @click="handleCourseSubmit">保 存</el-button>
      </div>
    </el-dialog>
    <catalog-setting :catalogSettingDialogVisible.sync="courseSettingDialogVisible"
                     :catalogSettingDialogHandleType="handleType"
                     :catalogSettingDialogType="courseSettingType"
                     :formData="courseSettingData"
                     :courseId="courseId"
                     @refreshPage="refreshCourseIntroPage"></catalog-setting>
    <el-dialog :title="courseContentTitle"
               :visible.sync="courseContentVisible"
               :append-to-body="true"
               :destroy-on-close="true"
               @before-close="handleClose"
               @close="handleClose"
               width="50%">
      <el-form :model="courseContentForm"
               ref="courseContentForm"
               :rules="courseContentRules"
               label-width="6.25vw">
        <el-row>
          <el-col :span="24">
            <el-form-item class="form-choose-item"
                          label="名称："
                          prop="courseThemeName">
              <el-input class="form-input"
                        v-model="courseContentForm.courseThemeName"
                        placeholder="请输入名称"
                        :maxlength="50"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="封面："
                          class="form-choose-item">
              <el-upload class="avatar-uploader"
                         :action="uploadUrl"
                         :headers="uploadHeader"
                         :show-file-list="false"
                         :on-success="handleAvatarSuccess"
                         :before-upload="beforeAvatarUpload"
                         :accept="'.png,.jpg,jpeg'">
                <img v-if="courseContentForm.coverImg"
                     :src="courseContentForm.coverImg"
                     class="avatar" />
                <i v-if="courseContentForm.coverImg"
                   class="el-icon-error delete-icon"
                   @click.stop="delCourseContentImg"></i>
                <div v-else
                     class="avatar-uploader-info">
                  <img class="avatar-uploader-icon"
                       :src="uploadImgIcon" />
                  <div class="avatar-uploader-title">上传图片</div>
                  <div class="avatar-uploader-tip">图片尺寸比例4:3，仅限上传1张</div>
                </div>
              </el-upload>
              <!-- <div class="upload-tip">图片尺寸比例4:3，仅限上传1张</div> -->
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item"
                          label="描述：">
              <el-input class="form-input"
                        type="textarea"
                        :rows="5"
                        placeholder="请填写描述内容..."
                        v-model="courseContentForm.description"
                        resize="none"
                        :maxlength="100"
                        show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button :loading="buttonloading"
                   @click="handleClose">取 消</el-button>
        <el-button :loading="buttonloading"
                   type="primary"
                   @click="handleSubmit">保 存</el-button>
      </div>
    </el-dialog>
    <content-component ref="contentComponent"
                       :contentDialogVisible.sync="contentDialogVisible"
                       :contentDialogHandleType="contentDialogHandleType"
                       :contentDialogType="contentDialogType"
                       :formData="contentData"
                       @getFileData="getContentInfo"></content-component>
    <upload-video ref="uploadVideo"
                  :uploadVideoDialogVisible.sync="uploadVideoDialogVisible"
                  :uploadVideoHandleType="uploadVideoHandleType"
                  :formData="videoData"
                  @getFileData="getVideoInfo"></upload-video>
    <upload-file ref="uploadFile"
                 :uploadFileDialogVisible.sync="uploadFileDialogVisible"
                 :uploadFileHandleType="uploadFileHandleType"
                 :formData="fileData"
                 @getFileData="getFileInfo"></upload-file>
    <video-view-dialog ref="videoView"
                       :videoViewDialogVisible.sync="videoViewDialogVisible"
                       :type="videoViewType"
                       :videoUrl="videoViewUrl"
                       @closeDialog="videoViewClose"></video-view-dialog>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import CatalogSetting from "@/components/catalogSetting/index.vue";
import contentComponent from "@/components/contentComponent/index.vue";
import uploadVideo from "@/components/uploadVideo/index.vue";
import uploadFile from "@/components/uploadFile/index.vue";
import VideoViewDialog from "@/components/videoView/index.vue";
import imgOfflineIcon from "@/assets/img/img-offline-icon.png";
import imgOnlineIcon from "@/assets/img/img-online-icon.png";
import imgNopushIcon from "@/assets/img/img-nopush-icon.png";
import detailOfflineIcon from "@/assets/img/detail-offline-icon.png";
import detailOnlineIcon from "@/assets/img/detail-online-icon.png";
import detailEditIcon from "@/assets/img/detail-edit-icon.png";
import detailDelIcon from "@/assets/img/detail-del-icon.png";
import detailOfflineIconHeader from "@/assets/img/detail-offline-icon-header.png";
import detailOnlineIconHeader from "@/assets/img/detail-online-icon-header.png";
import detailEditIconHeader from "@/assets/img/detail-edit-icon-header.png";
import detailDelIconHeader from "@/assets/img/detail-del-icon-header.png";
import emptyImg from "@/assets/img/empty-img.png";
import firstPanelTurnIcon from "@/assets/img/first-panel-turn-icon.png";
import editIcon from "@/assets/img/edit-icon.png";
import uploadFileIcon from "@/assets/img/upload-file-icon.png";
import panelDelIcon from "@/assets/img/panel-del-icon.png";
import fileViewIcon from "@/assets/img/file-view-icon.png";
import fileDownloadIcon from "@/assets/img/file-download-icon.png";
import delIcon from "@/assets/img/del-icon.png";
import videoAddIcon from "@/assets/img/video-add-icon.png";
import secondPanelTurnIcon from "@/assets/img/second-panel-turn-icon.png";
import catalogSettingIcon from "@/assets/img/catalog-setting-icon.png";
import offlineIcon from "@/assets/img/offline-icon.png";
import onlineIcon from "@/assets/img/online-icon.png";
import fileIconPDF from "@/assets/img/file-icon-PDF.png";
import fileIconWord from "@/assets/img/file-icon-word.png";
import fileIconExcel from "@/assets/img/file-icon-excel.png";
import fileIconPPT from "@/assets/img/file-icon-ppt.png";
import uploadImgIcon from '@/assets/img/upload-img-icon.png'
import videoPlayIcon from '@/assets/img/play-icon.png';
import defaultImg from '@/assets/img/default-img.png';
import defaultHeaderImg from "@/assets/img/default-header-img.png";
import defaultHeaderBg from "@/assets/img/detail-header-bg.png";
import detailCatalogContentSettingActiveIcon from '@/assets/img/detail-content-catalog-setting-active-icon.png';
import detailCatalogContentSettingIcon from '@/assets/img/detail-content-catalog-setting-icon.png';
import { getToken } from "@/utils/auth";
import { noSymbol } from "@/utils/validate.js";
import { getFileViewUrl, exportFile } from "@/utils/fileUtils.js";
import { getAgeScopeName, getCourseLevelName, getCourseTypeName, getCourseFieldName, getCourseVersionName, getCourseThemeName } from "@/utils/getDicName";
import { getPermissionButton } from "@/utils/getUrlPermissionButtons";
export default {
  name: "courseEdit",
  components: { CatalogSetting, contentComponent, uploadVideo, uploadFile, VideoViewDialog },
  inject: ["tagClose"],
  data () {
    let checkName = (rule, value, callback) => {
      if (value === "") {
        return callback();
      }
      if (value !== "" && !noSymbol(value)) {
        callback(new Error('只支持汉字、数字、字母组合'));
      } else {
        callback();
      }
    };
    return {
      searchForm: {
        status: "",
      }, //查询条件
      courseContentList: [], //主题Or活动列表
      isEmpty: false, //主题Or活动是否为空
      pagination: false, //分页标识
      ipagination: {
        num: 1,
        size: 12,
        pageSizes: [12, 24, 60, 120, 600],
        total: 0,
      }, // 分页信息
      statusOptions: [
        { name: "草稿", value: "0", },
        { name: "上架", value: "1", },
        { name: "下架", value: "2", },
      ], //状态下拉

      activeTab: "courseIntroduce", //介绍和内容切换
      courseInfo: {
        coverImg: "",
        courseName: "",
        courseField: "",
        courseTheme: "",
        courseVersion: "",
        courseClassify: "",
        courseLevel: "",
        courseSuitable: "",
        userName: "",
        createTime: "",
      }, //课程基本信息

      courseTitle: "", //课程基础信息弹窗标题
      courseVisible: false, //课程基础信息弹窗显隐标识
      courseForm: {
        courseName: "",
        coverImg: "",
        courseClassify: [],
        courseField: [],
        courseTheme: "",
        courseVersion: "",
        levelOrStep: "",
        courseLevel: [],
        courseSuitable: [],
        description: "",
        sort: ""
      }, //查询条件
      rules: {
        courseName: [
          { required: true, message: "请输入名称", trigger: "blur" },
          { min: 2, message: "名称长度不能小于2", trigger: "blur" },
          // { validator: checkName, trigger: 'blur' }
        ],
        courseClassify: [
          { type: 'array', required: true, message: "请选择分类", trigger: "change" },
        ],
        courseField: [
          { type: 'array', required: true, message: "请选择领域", trigger: "change" },
        ],
        courseTheme: [
          { required: true, message: "请选择课程系列", trigger: "change" },
        ],
        courseVersion: [
          { required: true, message: "请选择课程版本", trigger: "change" },
        ],
        levelOrStep: [
          { required: true, message: "请选择等级or阶段", trigger: "change" },
        ],
        courseLevel: [
          { type: 'array', required: true, message: "请选择等级", trigger: "change" },
        ],
        courseSuitable: [
          { type: 'array', required: true, message: "请选择阶段", trigger: "change" },
        ],
      },
      courseId: null, //编辑课程id
      handleType: "", //操作类型
      courseSettingDialogVisible: false, //内容设置弹窗
      courseSettingData: {}, //内容设置传值
      courseSettingType: "", //内容设置类型

      courseContentTitle: "", //主题or活动信息弹窗标题
      courseContentVisible: false, //主题or活动信息弹窗显隐标识
      courseContentForm: {
        courseThemeName: "",
        coverImg: "",
        description: "",
      }, //弹窗form条件
      courseContentRules: {
        courseThemeName: [
          { required: true, message: "请输入名称", trigger: "blur" },
          { min: 2, message: "名称长度不能小于2", trigger: "blur" },
          // { validator: checkName, trigger: 'blur' }
        ],
      },

      buttonloading: false, //弹框按钮loading
      pageLoading: null, //页面loading
      courseContentId: "", //编辑主题or活动id
      uploadUrl: (process.env.NODE_ENV == "development" ? process.env.VUE_APP_LOCAL_URL + "/webForm" : process.env.VUE_APP_LOCAL_URL) + "/api/upload/uploadImgToOSS", //上传地址
      uploadHeader: { token: this.getToken() },
      courseIntroInfo: [], //课程介绍详情
      secondInfo: {}, //二级对象
      scondIndex: null, //二级对象下标
      firstInfo: {}, //一级对象
      firstIndex: null, //一级对象下标

      imgOfflineIcon, //图片下架icon
      imgOnlineIcon, //图片上架icon
      imgNopushIcon, //图片未发布icon
      detailOfflineIcon, //详情下架icon
      detailOnlineIcon, //详情上架icon
      detailEditIcon, //详情编辑icon
      detailDelIcon, //详情删除icon
      detailOfflineIconHeader, //详情下架iconnew
      detailOnlineIconHeader, //详情上架iconnew
      detailEditIconHeader, //详情编辑iconnew
      detailDelIconHeader, //详情删除iconnew
      emptyImg, //空数据图片
      firstPanelTurnIcon, //一级折叠icon
      editIcon, //编辑icon
      uploadFileIcon, //附件上传icon
      panelDelIcon, //层删除icon
      fileViewIcon, //文件预览icon
      fileDownloadIcon, //文件下载icon
      delIcon, //删除icon
      videoAddIcon, //视频添加icon
      secondPanelTurnIcon, //二级折叠icon
      catalogSettingIcon, //课程内容的目录icon
      offlineIcon, //课程内容下架icon
      onlineIcon, //课程内容上架icon
      fileIconPDF, //pdfIcon
      fileIconWord, //wordIcon
      fileIconExcel, //excelIcon
      fileIconPPT,//PPTIcon
      uploadImgIcon, //上传按钮
      videoPlayIcon, //视频icon
      defaultImg, //默认图片
      defaultHeaderImg, //头部信息默认图片
      defaultHeaderBg, //头部背景图
      detailCatalogContentSettingActiveIcon, //内容设置hover按钮
      detailCatalogContentSettingIcon, //内容设置按钮

      contentDialogVisible: false, //内容编辑弹窗标识
      contentDialogHandleType: "", //内容编辑弹窗操作类型
      contentDialogType: "", //内容弹窗类型
      contentData: {}, //内容弹窗数据

      uploadVideoDialogVisible: false, //视频上传弹窗标识
      uploadVideoHandleType: "", //视频上传处理类型
      videoData: {}, //视频上传数据

      uploadFileDialogVisible: false, //文件上传弹窗标识
      uploadFileHandleType: "", //文件上传处理类型
      fileData: {}, //文件上传数据
      fileTypeList: ["png", "jpg", "jpeg"], //文件类型list

      videoViewDialogVisible: false, //视频预览标识
      videoViewType: "", //视频类型
      videoViewUrl: "",//视频预览url

      videoSpan: 6, //video宽度
      videoNum: 4, //video个数
      fileNum: 4, //附件个数

      courseContentSpan: 5, //课程内容宽度
      courseContentNum: 4, //课程内容个数
      courseContentRight: '5.2vw',

      isContentSettingHover: false,//内容设置按钮hover显隐标识

      permissionButtonList: [], //权限按钮list
      isUpdate: false,
      isDelete: false,
      isSetting: false,
      isUpDown: false,

      viewUrl: '', //附件图片预览
      viewUrlList: [],//附件图片预览list
    };
  },
  computed: {
    ...mapGetters(["permissions"]),
    ...mapState({
      typeOptions: (state) => state.common.courseTypeList,
      areaOptions: (state) => state.common.courseFieldList,
      stepOptions: (state) => state.common.ageScopeList,
      levelOptions: (state) => state.common.courseLevelList,
      themeOptions: (state) => state.common.courseThemeList,
      versionOptions: (state) => state.common.courseVersionList,
      menuList: (state) => state.common.menuList,
    }),
    showNum () {
      let start = (this.ipagination.num - 1) * this.ipagination.size + 1;
      let end = this.ipagination.num * this.ipagination.size > this.ipagination.total ? this.ipagination.total : this.ipagination.num * this.ipagination.size;
      return this.ipagination.total === 0 ? this.ipagination.total : `${start}-${end}`;
    },
  },
  watch: {
    permissionButtonList: {
      handler (val) {
        if (val) {
          this.isUpdate = val.indexOf("course:update") != -1;
          this.isDelete = val.indexOf("course:delete") != -1;
          this.isSetting = val.indexOf("course:config") != -1;
          this.isUpDown = val.indexOf("course:updown") != -1
        }
      },
      deep: true,
    },
    menuList: {
      handler (val) {
        if (val) {
          this.getPermissionButton("/courseManage", (data) => {
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
  },
  created () {
    this.courseId = Number(this.$route.query.id);
    this.pageLoading = this.$loading({
      lock: true,
      fullscreen: true,
      text: '加载中',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    });
    this.getPermissionButton("/courseManage", (data) => {
      this.permissionButtonList = data;
    });
    setTimeout(() => {
      this.getData();
      this.activeTab = 'courseContent'
      this.getCourseContentInfo();
      // this.getCourseIntroInfo();
    }, 300)
    let that = this
    window.onresize = function () {
      that.resizeVideoInfo()
    }
    // that.resizeVideoInfo()
  },
  methods: {
    getToken,
    getAgeScopeName,
    getCourseLevelName,
    getCourseTypeName,
    getCourseFieldName,
    getCourseVersionName,
    getCourseThemeName,
    getPermissionButton,
    getFileViewUrl,
    exportFile,
    //调整video个数和宽度
    resizeVideoInfo () {
      console.log(window.outerWidth)
      if (window.outerWidth <= 1800 && window.outerWidth > 1300) {
        this.videoSpan = 7
        this.videoNum = 4
        this.fileNum = 3
      } else if (window.outerWidth <= 1300) {
        this.videoSpan = 10
        this.videoNum = 3
        this.fileNum = 3
      } else if (window.outerWidth > 1800) {
        this.videoSpan = 5
        this.videoNum = 4
        this.fileNum = 3
      }

      if (window.outerWidth <= 1800 && window.outerWidth > 1400) {
        this.fileNum = 3
      } else if (window.outerWidth <= 1400) {
        this.fileNum = 3
      } else if (window.outerWidth > 1800) {
        this.fileNum = 3
      }

      if (window.outerWidth <= 1800 && window.outerWidth > 1600) {
        this.courseRight = '4.3vw'
      } else if (window.outerWidth <= 1600 && window.outerWidth > 1500) {
        this.courseRight = '4vw'
      } else if (window.outerWidth <= 1500 && window.outerWidth > 1400) {
        this.courseRight = '3.2vw'
      } else if (window.outerWidth <= 1400 && window.outerWidth > 1370) {
        this.courseRight = '3vw'
      } else if (window.outerWidth <= 1370 && window.outerWidth > 1300) {
        this.courseRight = '2.7vw'
      } else if (window.outerWidth <= 1300) {
        this.courseRight = '2.2vw'
      } else if (window.outerWidth > 1800) {
        this.courseRight = '5.2vw'
      }
    },
    //tab切换
    handeTabClick (val) {
      this.pageLoading = this.$loading({
        lock: true,
        fullscreen: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.activeTab = val
      if (val == "courseIntroduce") {
        this.getCourseIntroInfo();
      } else if (val == "courseContent") {
        this.getCourseContentInfo();
      }
    },
    //处理hover事件
    handleHover (type, flag) {
      if (type == 'content') {
        this.isContentSettingHover = flag
      }
    },
    //获取课程详情
    getData () {
      this.$api.getCourseInfo(this.courseId).then((res) => {
        if (res.data.code === 0) {
          console.log(res.data.data);
          let info = res.data.data

          let courseClassifyList = info.courseClassify.split(",")
          let courseClassifyName = []
          courseClassifyList.map((item) => {
            let name = this.getCourseTypeName(item)
            if (name) {
              courseClassifyName.push(name)
            }
          })

          let courseFieldList = info.courseField.split(",")
          let courseFieldName = []
          courseFieldList.map((item) => {
            let name = this.getCourseFieldName(item)
            if (name) {
              courseFieldName.push(name)
            }
          })

          let courseSuitableList = info.courseSuitable.split(",")
          let courseSuitableName = []
          courseSuitableList.map((item) => {
            let name = this.getAgeScopeName(item)
            if (name) {
              courseSuitableName.push(name)
            }
          })

          let courseLevelList = info.courseLevel.split(",")
          let courseLevelName = []
          courseLevelList.map((item) => {
            let name = this.getCourseLevelName(item)
            if (name) {
              courseLevelName.push(name)
            }
          })

          let courseThemeName = this.getCourseThemeName(info.courseTheme)

          let courseVersionName = this.getCourseVersionName(info.courseVersion)

          this.courseInfo = { ...res.data.data };
          this.courseInfo.courseClassifyName = courseClassifyName.join(",")
          this.courseInfo.courseFieldName = courseFieldName.join(",")
          this.courseInfo.courseSuitableName = courseSuitableName.join(",")
          this.courseInfo.courseLevelName = courseLevelName.join(",")
          this.courseInfo.courseThemeName = courseThemeName
          this.courseInfo.courseVersionName = courseVersionName
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //获取课程介绍详情
    getCourseIntroInfo () {
      this.$api.getCourseIntroInfo({ courseId: this.courseId }).then((res) => {
        this.pageLoading.close();
        if (res.data.code === 0) {
          console.log(res.data.data);
          res.data.data.map((item) => {
            item.showFlag = true;
            if (item.eduCourseIntroFile && item.eduCourseIntroFile.length > 0) {
              item.eduCourseIntroFile.map((fileInfo) => {
                let type = fileInfo.fileUrl.substr(fileInfo.fileUrl.lastIndexOf("."), fileInfo.fileUrl.length)
                if (type.toLowerCase().indexOf("pdf") != -1) {
                  fileInfo.iconType = 'pdf'
                }
                if (type.toLowerCase().indexOf("doc") != -1) {
                  fileInfo.iconType = 'doc'
                }
                if (type.toLowerCase().indexOf("xls") != -1) {
                  fileInfo.iconType = 'xls'
                }
                if (type.toLowerCase().indexOf("ppt") != -1) {
                  fileInfo.iconType = "ppt";
                }
              })
            }
            // if(item.eduCourseIntroVideo && item.eduCourseIntroVideo.length > 0){
            //   let list = JSON.parse(JSON.stringify(item.eduCourseIntroVideo)) 
            //   let resultList = list.concat(list).concat(list).concat(list).concat(list)
            //   item.eduCourseIntroVideo = resultList
            // }
            if (item.eduCourseIntroContentTree) {
              item.eduCourseIntroContentTree.map((info) => {
                info.showFlag = true;
                if (info.eduCourseIntroFile && info.eduCourseIntroFile.length > 0) {
                  let fileList = info.eduCourseIntroFile
                  fileList.map((fileInfo) => {
                    let type = fileInfo.fileUrl.substr(fileInfo.fileUrl.lastIndexOf("."), fileInfo.fileUrl.length)
                    if (type.toLowerCase().indexOf("pdf") != -1) {
                      fileInfo.iconType = 'pdf'
                    }
                    if (type.toLowerCase().indexOf("doc") != -1) {
                      fileInfo.iconType = 'doc'
                    }
                    if (type.toLowerCase().indexOf("xls") != -1) {
                      fileInfo.iconType = 'xls'
                    }
                    if (type.toLowerCase().indexOf("ppt") != -1) {
                      fileInfo.iconType = "ppt";
                    }
                  })
                  info.eduCourseIntroFile = fileList
                }
              });
            }
          });
          console.log(res.data.data);
          this.courseIntroInfo = res.data.data;
          this.$nextTick(() => {
            this.resizeVideoInfo()
          })
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(() => {
        this.pageLoading.close();
      });
    },
    //设置介绍内容
    handleCourseIntro () {
      this.courseSettingDialogVisible = true;
      this.handleType = "add";
      this.$nextTick(() => {
        this.courseSettingType = "introduce";
      });
    },
    //头部设置介绍内容
    handleCatalogItem () {
      this.courseSettingDialogVisible = true;
      this.handleType = "edit";
      this.$nextTick(() => {
        this.courseSettingType = "introduce";
      });
    },
    //刷新介绍
    refreshCourseIntroPage (type) {
      this.courseSettingDialogVisible = false;
      this.$nextTick(() => {
        this.courseSettingType = "";
        this.handleType = "";
        this.pageLoading = this.$loading({
          lock: true,
          fullscreen: true,
          text: '加载中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.getCourseIntroInfo();
      });
    },
    //获取课程内容详情
    getCourseContentInfo () {
      let params = {
        courseId: this.courseId,
        delFlag: this.searchForm.status || undefined,
      };
      params.current = this.ipagination.num;
      params.size = this.ipagination.size;
      this.$api.getCourseContentList(params).then((res) => {
        this.pageLoading.close();
        if (res.data.code === 0) {
          this.courseContentList = res.data.data.records;
          this.ipagination.total = res.data.data.total;
          if (res.data.data.total > 0) {
            this.pagination = true;
          } else {
            this.pagination = false;
          }
          // if(this.searchForm.status === "" && res.data.data.total == 0){
          //   this.isEmpty = true
          // } else {
          //   this.isEmpty = false
          // }
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(() => {
        this.pageLoading.close();
      });
    },
    //内容操作
    handleContentItem (type, secondItem, secondIndex, firstItem, firstIndex) {
      this.secondInfo = secondItem;
      this.secondIndex = secondIndex;
      this.firstInfo = firstItem;
      this.firstIndex = firstIndex;
      switch (type) {
        case "addFile":
          this.uploadFileDialogVisible = true;
          this.$nextTick(() => {
            this.uploadFileHandleType = "add";
          });
          break;
        case "editItem":
          this.contentDialogVisible = true;
          this.$nextTick(() => {
            let catalogueName = secondItem ? secondItem.catalogueName : firstItem.catalogueName;
            let eduCourseIntroContentInfo = secondItem ? secondItem.eduCourseIntroContent : firstItem.eduCourseIntroContent;
            let content = "";
            if (eduCourseIntroContentInfo.length > 0) {
              content = eduCourseIntroContentInfo[0].content;
              this.contentData = {
                id: eduCourseIntroContentInfo[0].id,
                name: catalogueName,
                content,
              };
              this.contentDialogHandleType = "edit";
            } else {
              content = "";
              this.contentData = {
                id: "",
                name: catalogueName,
                content,
              };
              this.contentDialogHandleType = "add";
            }
            console.log(this.contentData);
            this.contentDialogType = "normal";
          });
          break;
        case "addVideo":
          this.uploadVideoDialogVisible = true;
          this.$nextTick(() => {
            this.uploadVideoHandleType = "add";
          });
          break;
        case "turnItem":
          let info = {};
          if (secondItem) {
            info = { ...secondItem };
            info.showFlag = !info.showFlag;
            this.$set(this.courseIntroInfo[firstIndex].eduCourseIntroContentTree, secondIndex, info);
          } else {
            info = { ...firstItem };
            info.showFlag = !info.showFlag;
            this.$set(this.courseIntroInfo, firstIndex, info);
          }
          break;
      }
    },
    //预览富文本图片
    handleImgClick (e) {
      console.log(e)
      if (e.target.nodeName == 'IMG') {
        this.viewUrl = e.target.currentSrc
        this.viewUrlList = [e.target.currentSrc]
        this.$nextTick(() => {
          this.$refs.viewImg.showViewer = true
        })
      }
    },
    //预览文件
    fileView (fileInfo, type) {
      if (type == 'view') {
        let type = fileInfo.fileUrl.substr(fileInfo.fileUrl.lastIndexOf(".") + 1, fileInfo.fileUrl.length)
        if (this.fileTypeList.indexOf(type.toLowerCase()) != -1) {
          this.viewUrl = fileInfo.fileUrl
          this.viewUrlList = [fileInfo.fileUrl]
          this.$nextTick(() => {
            this.$refs.viewImg.showViewer = true
          })
        } else {
          // window.location.href = fileInfo.fileUrl
          this.getFileViewUrl(fileInfo.fileUrl, fileInfo.originalName, (data) => {
            if (data) {
              sessionStorage.setItem("viewUrl", data)
              let routeUrl = this.$router.resolve({ path: "/fileView.html" });
              window.open(routeUrl.href, '_blank');
            }
          })
        }
      } else {
        if (fileInfo.isDownload === '1') {
          let type = fileInfo.fileUrl.substr(fileInfo.fileUrl.lastIndexOf(".") + 1, fileInfo.fileUrl.length)
          if (this.fileTypeList.indexOf(type.toLowerCase()) != -1) {
            this.$message.warning("该附件为图片，只可预览查看")
          } else {
            this.exportFile(fileInfo.fileUrl, {}, fileInfo.originalName)
            // const link = document.createElement("a");
            // link.href = fileInfo.fileUrl;
            // link.setAttribute("download", fileInfo.originalName);
            // document.body.appendChild(link);
            // link.click();
            // window.location.href = fileInfo.fileUrl
          }
        } else {
          this.$message.warning("该附件不支持下载！")
        }
      }
    },
    //删除文件
    delFile (fileInfo) {//"确定删除该附件, 是否继续?"
      const h = this.$createElement;
      this.$confirm(
        h('p', null, [
          h('span', { style: 'color: rgba(0, 0, 0, 0.85);font-size: 16px;font-weight: 500;' }, '确定要删除该附件？'),
          h('div', { style: 'color: rgba(0, 0, 0, 0.65);font-size: 14px;margin-top: 10px' }, '是否确定删除该附件，删除后，将无法恢复。')
        ]), "提示", {
        confirmButtonText: "确定", cancelButtonText: "取消", iconClass: "el-icon-question", type: "warning", customClass: "warningMessage"
      }).then(() => {
        this.pageLoading = this.$loading({
          lock: true,
          fullscreen: true,
          text: '操作中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.$api.introContentFileHandle("/api/educourseintrofile/del", { id: fileInfo.id, delFlag: "1" }, "del").then((res) => {
          if (res.data.code === 0) {
            this.$message({
              type: "success", message: "删除成功!", duration: 2000, onClose: () => {
                this.pageLoading.close();
                this.getCourseIntroInfo();
              },
            });
          } else {
            this.pageLoading.close();
            this.$message.error(res.data.msg);
          }
        }).catch(() => {
          this.pageLoading.close();
        });
      }).catch(() => { });
    },
    //视频预览
    videoView (videoInfo) {
      this.videoViewDialogVisible = true
      this.$nextTick(() => {
        let type = videoInfo.videoUrl.substr(videoInfo.videoUrl.lastIndexOf(".") + 1, videoInfo.videoUrl.length)
        console.log(type)
        this.videoViewType = (type == 'mp4' || type == 'avi') ? 'video' : (type == 'mp3' ? 'audio' : '')
        this.videoViewUrl = videoInfo.videoUrl
      })
    },
    //视频预览关闭
    videoViewClose () {
      this.videoViewDialogVisible = false
      this.$nextTick(() => {
        this.videoViewType = ""
        this.videoViewUrl = ""
      })
    },
    //编辑视频
    editVideo (videoInfo, videoIndex, secondItem, secondIndex, firstItem, firstIndex) {
      this.secondInfo = secondItem;
      this.secondIndex = secondIndex;
      this.firstInfo = firstItem;
      this.firstIndex = firstIndex;
      this.uploadVideoDialogVisible = true;
      this.$nextTick(() => {
        this.uploadVideoHandleType = "edit";
        this.videoData = {
          id: videoInfo.id,
          videoName: videoInfo.videoName || "",
          imageUrl: videoInfo.fontImg || "",
          videoUrl: videoInfo.videoUrl,
          remark: videoInfo.intro,
        };
        console.log(this.videoData);
      });
    },
    //删除视频
    delVideo (videoInfo) {//"确定删除该视频, 是否继续?"
      const h = this.$createElement;
      this.$confirm(
        h('p', null, [
          h('span', { style: 'color: rgba(0, 0, 0, 0.85);font-size: 16px;font-weight: 500;' }, '确定要删除该视频？'),
          h('div', { style: 'color: rgba(0, 0, 0, 0.65);font-size: 14px;margin-top: 10px' }, '是否确定删除该视频，删除后，将无法恢复。')
        ]), "提示", {
        confirmButtonText: "确定", cancelButtonText: "取消", iconClass: "el-icon-question", type: "warning", customClass: "warningMessage"
      }).then(() => {
        this.pageLoading = this.$loading({
          lock: true,
          fullscreen: true,
          text: '操作中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.$api.introVideoHandle("/api/educourseintrovideo/del", { id: videoInfo.id, delFlag: "1" }, "del").then((res) => {
          if (res.data.code === 0) {
            this.$message({
              type: "success", message: "删除成功!", duration: 2000, onClose: () => {
                this.pageLoading.close()
                this.getCourseIntroInfo();
              },
            });
          } else {
            this.pageLoading.close();
            this.$message.error(res.data.msg);
          }
        }).catch(() => {
          this.pageLoading.close();
        });
      }).catch(() => { });
    },
    //查询
    searchFun () {
      this.ipagination.num = 1;
      this.pageLoading = this.$loading({
        lock: true,
        fullscreen: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.getCourseContentInfo();
    },
    //重置
    resetFun () {
      this.searchForm = {
        status: "",
      };
      this.ipagination.num = 1;
      this.pageLoading = this.$loading({
        lock: true,
        fullscreen: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.getCourseContentInfo();
    },
    //上传课程封面成功事件
    handleCourseAvatarSuccess (res, file) {
      console.log(res);
      this.courseForm.coverImg = res;
      this.pageLoading.close();
    },
    //上传封面之前事件
    beforeCourseAvatarUpload (file) {
      const isJPG = this.fileTypeList.indexOf(file.name.substr(file.name.lastIndexOf(".") + 1, file.name.length).toLowerCase()) !== -1;
      const isLt2M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        this.$message.error("上传图片只能是 png, jpg, jpeg 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 10MB!");
      }
      if (isJPG && isLt2M) {
        this.pageLoading = this.$loading({
          lock: true,
          fullscreen: true,
          text: '上传中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      }
      return isJPG && isLt2M; //&& isLt2M
    },
    //删除图片
    delCourseImg () {//"确定删除该封面图片, 是否继续?"
      const h = this.$createElement;
      this.$confirm(
        h('p', null, [
          h('span', { style: 'color: rgba(0, 0, 0, 0.85);font-size: 16px;font-weight: 500;' }, '确定要删除该封面图片？'),
          h('div', { style: 'color: rgba(0, 0, 0, 0.65);font-size: 14px;margin-top: 10px' }, '是否确定删除该封面图片，删除后，将无法恢复。')
        ]), "提示", {
        confirmButtonText: "确定", cancelButtonText: "取消", iconClass: "el-icon-question", type: "warning", customClass: "warningMessage"
      }).then(() => {
        this.courseForm.coverImg = "";
      }).catch(() => { });
    },
    //等级or阶段改变
    levelOrStepChange (val) {
      this.courseForm.levelOrStep = val;
      let propName = "";
      this.courseForm.courseLevel = [];
      this.courseForm.courseSuitable = [];
      if (val == "1") {
        propName = "courseLevel";
      } else if (val == "2") {
        propName = "courseSuitable";
      }
      this.$refs.courseForm.clearValidate(propName);
    },
    //弹框关闭
    handleCourseClose () {
      this.courseVisible = false;
      this.$nextTick(() => {
        this.courseForm = {
          courseName: "",
          coverImg: "",
          courseClassify: [],
          courseField: [],
          courseTheme: "",
          courseVersion: "",
          levelOrStep: "",
          courseLevel: [],
          courseSuitable: [],
          description: "",
          sort: ""
        };
        this.courseTitle = "";
        this.handleType = "";
        this.$refs.courseForm.clearValidate();
      });
    },
    //弹窗保存
    handleCourseSubmit () {
      this.$refs.courseForm.validate((valid) => {
        if (valid) {
          this.buttonloading = true;
          // let params = { ...this.courseForm };
          let params = {
            courseName: this.courseForm.courseName,
            coverImg: this.courseForm.coverImg,
            courseClassify: this.courseForm.courseClassify.join(","),
            courseField: this.courseForm.courseField.join(","),
            courseTheme: this.courseForm.courseTheme,
            courseVersion: this.courseForm.courseVersion,
            courseLevel: this.courseForm.courseLevel.join(","),
            courseSuitable: this.courseForm.courseSuitable.join(","),
            description: this.courseForm.description,
            sort: this.courseForm.sort
          };
          let url = "/api/educourse";
          params.id = this.courseId;
          this.$api.courseHandle(url, params, "edit").then((res) => {
            if (res.data.code === 0) {
              this.$message({
                message: "保存成功", type: "success", duration: 2000, onClose: () => {
                  this.buttonloading = false;
                  this.handleCourseClose();
                  this.getData();
                },
              });
            } else {
              this.$message.error(res.data.msg);
              this.buttonloading = false;
            }
          }).catch(() => {
            this.buttonloading = false;
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //编辑课程基本信息
    courseEdit () {
      this.courseVisible = true;
      this.$nextTick(() => {
        this.courseTitle = "编辑";
        this.handleType = "edit";
        this.courseForm.courseName = this.courseInfo.courseName;
        this.courseForm.coverImg = this.courseInfo.coverImg;
        this.courseForm.courseClassify = this.courseInfo.courseClassify.split(",");
        this.courseForm.courseField = this.courseInfo.courseField.split(",");
        this.courseForm.courseTheme = this.courseInfo.courseTheme;
        this.courseForm.courseVersion = this.courseInfo.courseVersion;
        this.courseForm.levelOrStep = this.courseInfo.courseLevel !== "" && this.courseInfo.courseSuitable !== "" ? "2" : this.courseInfo.courseLevel ? "1" : this.courseInfo.courseSuitable ? "2" : "";
        this.courseForm.courseSuitable = this.courseInfo.courseSuitable.split(",");
        this.courseForm.courseLevel = this.courseInfo.courseLevel.split(",");
        this.courseForm.description = this.courseInfo.description;
        this.courseForm.sort = this.courseInfo.sort;
      });
    },
    //上下架
    changeStatus (type) {
      this.$api.courseHandle("/api/educourse", { id: this.courseId, courseName: this.courseInfo.courseName, status: type }, "edit").then((res) => {
        if (res.data.code === 0) {
          this.$message({
            message: type == "1" ? "上架成功" : "下架成功", type: "success", duration: 2000, onClose: () => {
              this.getData();
            },
          });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //删除
    deleteCourse (id) {//"确定删除该课程, 是否继续?"
      const h = this.$createElement;
      this.$confirm(
        h('p', null, [
          h('span', { style: 'color: rgba(0, 0, 0, 0.85);font-size: 16px;font-weight: 500;' }, '确定要删除该课程？'),
          h('div', { style: 'color: rgba(0, 0, 0, 0.65);font-size: 14px;margin-top: 10px' }, '是否确定删除该课程，删除后，将无法恢复。')
        ]), "提示", {
        confirmButtonText: "确定", cancelButtonText: "取消", iconClass: "el-icon-question", type: "warning", customClass: "warningMessage"
      }).then(() => {
        this.$api.delCourse({ id: this.courseId, delFlag: "1" }).then((res) => {
          if (res.data.code === 0) {
            this.$message({
              type: "success", message: "删除成功!", duration: 2000, onClose: () => {
                this.tagClose(this.$route.fullPath);
              },
            });
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }).catch(() => { });
    },
    //刷新列表
    refreshPage (type) {
      this.pageLoading = this.$loading({
        lock: true,
        fullscreen: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.getData();
      this.getCourseIntroInfo();
    },
    //上传封面成功事件
    handleAvatarSuccess (res, file) {
      this.courseContentForm.coverImg = res;
      this.pageLoading.close()
    },
    //上传封面之前事件
    beforeAvatarUpload (file) {
      const isJPG = this.fileTypeList.indexOf(file.name.substr(file.name.lastIndexOf(".") + 1, file.name.length).toLowerCase()) !== -1;
      const isLt2M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        this.$message.error("上传图片只能是 png, jpg, jpeg 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 10MB!");
      }
      if (isJPG && isLt2M) {
        this.pageLoading = this.$loading({
          lock: true,
          fullscreen: true,
          text: '上传中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      }
      return isJPG && isLt2M; //&& isLt2M
    },
    //删除课程内容封面
    delCourseContentImg () {//"确定删除该封面图片, 是否继续?"
      const h = this.$createElement;
      this.$confirm(
        h('p', null, [
          h('span', { style: 'color: rgba(0, 0, 0, 0.85);font-size: 16px;font-weight: 500;' }, '确定要删除该封面图片？'),
          h('div', { style: 'color: rgba(0, 0, 0, 0.65);font-size: 14px;margin-top: 10px' }, '是否确定删除该封面图片，删除后，将无法恢复。')
        ]), "提示", {
        confirmButtonText: "确定", cancelButtonText: "取消", iconClass: "el-icon-question", type: "warning", customClass: "warningMessage"
      }).then(() => {
        this.courseContentForm.coverImg = "";
      }).catch(() => { });
    },
    //添加主题Or活动
    handleAdd () {
      this.courseContentVisible = true;
      this.$nextTick(() => {
        this.courseContentTitle = "添加";
      });
    },
    //操作事件
    handleItem (type, row) {
      switch (type) {
        case "setting":
          this.$router.push({ path: "/courseManage/catalogSetting", query: { id: row.id }, });
          break;
        case "up":
          this.changeContentStatus("1", row);
          break;
        case "down":
          this.changeContentStatus("2", row);
          break;
        case "edit":
          this.courseContentVisible = true;
          this.$nextTick(() => {
            this.courseContentTitle = "编辑";
            this.courseContentId = row.id
            this.courseContentForm.courseThemeName = row.courseThemeName
            this.courseContentForm.coverImg = row.coverImg
            this.courseContentForm.description = row.description
          });
          break;
        case "del":
          this.deleteCourseContent(row.id);
          break;
        case "detail":
          this.courseContentId = row.id
          this.getCourseContentCatalogInfo((data, type) => {
            if (!data) {
              if (type === 1) {
                this.$message.warning("还未配置目录，请先配置目录")
              }
              return
            }
            this.$router.push({ path: "/courseManage/contentSetting", query: { id: row.id, courseId: this.courseId, courseStatus: this.courseInfo.status }, });
          })
          break;
      }
    },
    //获取课程内容目录list
    getCourseContentCatalogInfo (callback) {
      this.$api.getCourseContentCataLogList({ courseThemeId: this.courseContentId }).then((res) => {
        if (res.data.code === 0) {
          if (res.data.data.length > 0) {
            callback(true)
          } else {
            callback(false, 1)
          }
        } else {
          callback(false, 0)
          this.$message.error(res.data.msg);
        }
      });
    },
    //上下架
    changeContentStatus (type, row) {
      this.$api.courseContentHandle("/api/educoursetheme", { id: row.id, courseThemeName: row.courseThemeName, status: type }, "edit").then((res) => {
        if (res.data.code === 0) {
          this.$message({
            message: type == '1' ? "上架成功" : "下架成功", type: "success", duration: 2000, onClose: () => {
              this.pageLoading = this.$loading({
                lock: true,
                fullscreen: true,
                text: '操作中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
              });
              this.getCourseContentInfo();
            },
          });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //删除
    deleteCourseContent (id) {//"确定删除该课程内容, 是否继续?"
      const h = this.$createElement;
      this.$confirm(
        h('p', null, [
          h('span', { style: 'color: rgba(0, 0, 0, 0.85);font-size: 16px;font-weight: 500;' }, '确定要删除该课程内容？'),
          h('div', { style: 'color: rgba(0, 0, 0, 0.65);font-size: 14px;margin-top: 10px' }, '是否确定删除该课程内容，删除后，将无法恢复。')
        ]), "提示", {
        confirmButtonText: "确定", cancelButtonText: "取消", iconClass: "el-icon-question", type: "warning", customClass: "warningMessage"
      }).then(() => {
        this.$api.courseContentHandle("/api/educoursetheme/del", { id: id, delFlag: "1" }, 'del').then((res) => {
          if (res.data.code === 0) {
            this.$message({
              type: "success", message: "删除成功!", duration: 2000, onClose: () => {
                this.pageLoading = this.$loading({
                  lock: true,
                  fullscreen: true,
                  text: '加载中',
                  spinner: 'el-icon-loading',
                  background: 'rgba(0, 0, 0, 0.7)'
                });
                this.getCourseContentInfo();
              },
            });
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }).catch(() => { });
    },
    //页码改变
    handleCurrentChange (val) {
      this.ipagination.num = val;
      this.pageLoading = this.$loading({
        lock: true,
        fullscreen: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.getCourseContentInfo();
    },
    //每页大小改变
    handleSizeChange (size) {
      this.ipagination.size = size;
      this.ipagination.num = 1;
      this.pageLoading = this.$loading({
        lock: true,
        fullscreen: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.getCourseContentInfo();
    },
    //弹框关闭
    handleClose () {
      this.courseContentVisible = false;
      this.$nextTick(() => {
        this.courseContentForm = {
          courseThemeName: "",
          coverImg: "",
          description: "",
        };
        this.courseContentId = "";
        this.courseContentTitle = "";
        this.$refs.courseContentForm.clearValidate();
      });
    },
    //弹窗完成
    handleSubmit () {
      this.$refs.courseContentForm.validate((valid) => {
        if (valid) {
          this.buttonloading = true;
          let params = { ...this.courseContentForm };
          params.courseId = this.courseId;
          let url = "";
          if (this.courseContentId == "") {
            url = "/api/educoursetheme";
          } else {
            params.id = this.courseContentId;
            url = "/api/educoursetheme";
          }
          this.$api.courseContentHandle(url, params, this.courseContentId ? "edit" : "add").then((res) => {
            if (res.data.code === 0) {
              this.$message({
                message: "保存成功", type: "success", duration: 2000, onClose: () => {
                  this.buttonloading = false;
                  if (this.courseContentId == "") {
                    this.$router.push({ path: "/courseManage/contentSetting", query: { id: res.data.data.id, courseId: this.courseId, courseStatus: this.courseInfo.status }, });
                  } else {
                    this.pageLoading = this.$loading({
                      lock: true,
                      fullscreen: true,
                      text: '加载中',
                      spinner: 'el-icon-loading',
                      background: 'rgba(0, 0, 0, 0.7)'
                    });
                    this.getCourseContentInfo();
                  }
                  this.handleClose();
                },
              });
            } else {
              this.buttonloading = false;
              this.$message.error(res.data.msg);
            }
          }).catch(() => {
            this.pageLoading.close();
            this.buttonloading = false;
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //获取内容编辑操作
    getContentInfo (data) {
      let params = {
        courseIntroId: this.secondInfo ? this.secondInfo.id : this.firstInfo.id,
        catalogueName: data.formData.name,
        content: data.formData.content,
        id: data.formData.id,
      };
      let url = "";
      if (this.contentDialogHandleType == "add") {
        url = "/api/educourseintrocontent";
      } else if (this.contentDialogHandleType == "edit") {
        url = "/api/educourseintrocontent";
      }
      this.$api.introContentHandle(url, params, this.contentDialogHandleType).then((res) => {
        if (res.data.code === 0) {
          this.$message({
            type: "success", message: this.contentDialogType == "add" ? "内容添加成功" : "内容修改成功", duration: 2000, onClose: () => {
              this.contentDialogVisible = false;
              this.$nextTick(() => {
                this.contentData = {};
                this.contentDialogType = "";
                this.contentDialogHandleType = "";
                this.firstInfo = null;
                this.firstIndex = null;
                this.secondInfo = null;
                this.scondIndex = null;
                this.getCourseIntroInfo();
              });
            },
          });
        } else {
          this.$message.error(res.data.msg);
          this.$refs.contentComponent.buttonloading = false;
        }
      }).catch(() => {
        this.$refs.contentComponent.buttonloading = false;
      });
    },
    //获取视频编辑操作
    getVideoInfo (data) {
      let params = {
        videoName: data.formData.videoName,
        fontImg: data.formData.imageUrl,
        videoUrl: data.formData.videoUrl,
        intro: data.formData.remark,
      };
      let url = "";
      if (this.uploadVideoHandleType == "add") {
        params.courseIntroId = this.secondInfo ? this.secondInfo.id : this.firstInfo.id
        url = "/api/educourseintrovideo";
      } else if (this.uploadVideoHandleType == "edit") {
        url = "/api/educourseintrovideo";
        params.id = data.formData.id;
      }
      this.$api.introVideoHandle(url, params, this.uploadVideoHandleType).then((res) => {
        if (res.data.code === 0) {
          this.$message({
            type: "success", message: this.uploadVideoHandleType == "add" ? "视频添加成功" : "视频修改成功", duration: 2000, onClose: () => {
              this.uploadVideoDialogVisible = false;
              this.$nextTick(() => {
                this.videoData = {};
                this.uploadVideoHandleType = "";
                this.firstInfo = null;
                this.firstIndex = null;
                this.secondInfo = null;
                this.scondIndex = null;
                this.getCourseIntroInfo();
              });
            },
          });
        } else {
          this.$message.error(res.data.msg);
          this.$refs.uploadVideo.buttonloading = false;
        }
      }).catch(() => {
        this.$refs.uploadVideo.buttonloading = false;
      });
    },
    //获取文件编辑操作(保存附件)
    getFileInfo (data) {
      const { url, fileId, fileVersionId, name } = data.fileList[0]
      let params = {
        courseIntroId: this.secondInfo ? this.secondInfo.id : this.firstInfo.id,
        originalName: name,
        fileUrl: url,
        fileVersionId,
        fileId,
        remark: data.formData.remark,
        isDownload: data.formData.isDownload,
      };
      let urlStr = "";
      if (this.uploadFileHandleType == "add") {
        urlStr = "/api/educourseintrofile";
      } else if (this.uploadFileHandleType == "edit") {
        urlStr = "/api/educourseintrofile";
        // params.id = ""
      }
      this.$api.introContentFileHandle(urlStr, params, this.uploadFileHandleType).then((res) => {
        if (res.data.code === 0) {
          this.$message({
            type: "success", message: this.uploadFileHandleType == "add" ? "附件添加成功" : "附件修改成功", duration: 2000, onClose: () => {
              this.uploadFileDialogVisible = false;
              this.$nextTick(() => {
                this.uploadFileHandleType = "";
                this.firstInfo = null;
                this.firstIndex = null;
                this.secondInfo = null;
                this.scondIndex = null;
                this.getCourseIntroInfo();
              });
            },
          });
        } else {
          this.$message.error(res.data.msg);
          this.$refs.uploadFile.buttonloading = false;
        }
      }).catch(() => {
        this.$refs.uploadFile.buttonloading = false;
      });
    },
  },
  beforeDestroy () {
    window.onresize = null
  }
};
</script>
<style lang="scss" scoped>
.course-detail-title {
  position: relative;
  img {
    width: 17px;
    height: 17px;
    position: absolute;
    right: 24px;
    bottom: 19px;
    cursor: pointer;
  }
}
//tab样式
/deep/.el-tabs__nav-wrap {
  padding: 0 20px;
  height: 60px;
}

/deep/.el-tabs__nav {
  font-size: 20px;
  height: 60px;
  .el-tabs__item {
    height: 60px;
    line-height: 60px;
    font-size: 20px;
  }
}
.course-content-tab {
  padding: 30px 25px 20px 25px;
  background-color: #ffffff;
  .course-content-card {
    /deep/.el-card__body {
      padding: 0;
    }
  }
}
/deep/.el-textarea {
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  &:hover {
    border-color: #c0c4cc;
  }
}
/deep/.el-textarea__inner {
  margin-bottom: 18px;
  border: none;
}
/deep/.el-input__count {
  bottom: 10px;
  line-height: normal;
  &::before {
    content: '已输入 ';
  }
}
.courseForm {
  /deep/.form-choose-item .el-form-item__content {
    width: calc(100% - 160px);
  }
}
</style>
